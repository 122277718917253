body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
a:hover {
  color: darkblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-2nd {
  color: #ccc;
}

.pageFooter {
  position: fixed;
  bottom: 0;
  text-align: 'center';
  margin: 10px;
  padding: 10px;
}
.pageFooter a {
  background-color: #ffe;
  padding: 5px 10px;
  border-radius: 4px;
}